import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpRequest } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { AuthGuard } from './@core/guards/auth.guard';
import { LoginModule } from './auth/login/login.module';
import {
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbAuthJWTInterceptor,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NbAuthOAuth2JWTToken,
} from '@nebular/auth';

import { API_URL } from '../environments/environment';
import { LogoutComponent } from './auth/logout/logout.component';
import { RegisterModule } from './auth/register/register.module';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
  ],
  imports: [
    LoginModule,
    RegisterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            key: 'token',
            class: NbAuthOAuth2JWTToken,
          },
          baseEndpoint: API_URL,
          login: {
            endpoint: '?module=jackhammer.api&method=subManagerLogin',
            method: 'post',
            redirect: {
              success: '/pages/dashboard',
              failure: null,
            },
          },
          refreshToken: {
            endpoint: '?module=jackhammer.api&method=refreshToken',
            method: 'post',
          },
          logout: {
            endpoint: '?module=jackhammer.api&method=subManagerLogout',
            method: 'post',
            redirect: {
              success: '/auth/login',
              failure: null,
            },
          },
          register: {
            endpoint: '?module=jackhammer.api&method=subManagerRegister',
            method: 'post',
            redirect: {
              success: '/auth/login',
              failure: null,
            },
          },
          validation: {
            password: {
              required: true,
              minLength: 6,
              maxLength: 255,
            },
            fullName: {
              required: true,
              minLength: 5,
              maxLength: 100,
            },
          },
        }),
      ],
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: (req: HttpRequest<any>) => {
        if (
          req.url.indexOf(API_URL) > -1 &&
          req.url.indexOf('subManagerLogin') === -1 &&
          req.url.indexOf('subManagerRegister') === -1 &&
          req.url.indexOf('refreshToken') === -1
        ) {
          return false;
        }
        return true;
      },
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
}
