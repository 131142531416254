import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServerStatusService } from '../../../@core/services/server-status.service';

@Component({
  selector: 'ngx-server-status-window',
  templateUrl: './server-status-window.component.html',
  styleUrls: ['./server-status-window.component.scss'],
})
export class ServerStatusWindowComponent implements OnInit, OnDestroy {

  public serverStatus: {
    app: boolean[];
    dispatch: boolean[];
    radio: boolean;
    ims: boolean;
    bp: boolean;
  } = {
    app: [],
    dispatch: [],
    radio: null,
    ims: null,
    bp: null,
  };

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    protected windowRef: NbWindowRef,
    private serverStatusService: ServerStatusService,
  ) { }

  ngOnInit(): void {
    this.serverStatusService.getAppServerV2Status()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: any[]) =>
        this.serverStatus.app = status.map(server => server?.errorCode === 0),
      );

    this.serverStatusService.getDispatchServerStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: any[]) =>
        this.serverStatus.dispatch = status.map(server => server?.dispatches?.length > 0),
      );

    this.serverStatusService.getRadioServerStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(status => this.serverStatus.radio = status);

    this.serverStatusService.getIMSServerStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(status => this.serverStatus.ims = status);

    this.serverStatusService.getBPServerStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(status => this.serverStatus.bp = status);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
