import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { API_URL } from '../../../environments/environment';
import { NbThemeService } from '@nebular/theme';
import { handleError } from '../../common/functions/handleError';
import { DefaultResponse } from '../../common/interfaces/api-responses/default';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private user: any;

  constructor(
    private http: HttpClient,
    private themeService: NbThemeService,
  ) {
    let user = window.localStorage.user;

    if (user !== undefined && user !== 'undefined') {
      user = JSON.parse(window.localStorage.user);
      this.user = user;

      this.themeService.changeTheme(this.user.settings.theme);
    }
  }

  getUser(): any {
    return this.user;
  }

  setUser(user: any): void {
    this.user = user;
    window.localStorage.user = JSON.stringify(user);

    this.themeService.changeTheme(this.user.settings.theme);
  }

  /**
   * Gets a list of all App Preview users.
   */
  getAppPreviewUsers(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=userGetAppPreviewUsers`;
    return this.http.get(url)
    .pipe(
      tap(_ => console.log('got app preview users')),
      catchError(handleError('getAppPreviewUsers', [])),
    );
  }

  /**
   * Saves general user settings.
   *
   * @param settings - object containing the user ID, first name, last name
   * and optionally the linked IMS account.
   */
  saveUserSettings(settings: {
    userID: number;
    firstName: string;
    lastName: string;
    wrUserID?: string;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=userUpdateUserSettings`;
    return this.http.post(url, settings)
    .pipe(
      tap(_ => console.log('set user settings')),
      catchError(handleError('saveUserSettings', [])),
    );
  }

  /**
   * Saves the user's general display preferences.
   *
   * @param preferences - user's choice of theme and preferred animal
   */
  saveUserPreferences(preferences: {
    userID: number;
    theme: string;
    animal: string;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=userUpdateUserPreferences`;
    return this.http.post(url, preferences)
    .pipe(
      tap(_ => console.log('set user preferences')),
      catchError(handleError('saveUserPreferences', [])),
    );
  }

  getStaffNumbers(): Observable<(DefaultResponse & { numbers: Array<{ number: string }> })> {
    const url = `${API_URL}?module=jackhammer.internal.user&method=getStaffNumbers`;
    return this.http.get(url)
      .pipe(
        tap(_ => console.log('get staff numbers')),
        catchError(handleError('getStaffNumbers', [])),
      );
  }

  addStaffNumber(number: string): Observable<DefaultResponse> {
    const url = `${API_URL}?module=jackhammer.internal.user&method=addStaffNumber`;
    return this.http.post(url, {
      number,
    })
      .pipe(
        tap(_ => console.log('added staff number')),
        catchError(handleError('addStaffNumber', [])),
      );
  }

  removeStaffNumber(number: string): Observable<DefaultResponse> {
    const url = `${API_URL}?module=jackhammer.internal.user&method=removeStaffNumber`;
    return this.http.post(url, {
      number,
    })
      .pipe(
        tap(_ => console.log('removed staff number')),
        catchError(handleError('removeStaffNumber', [])),
      );
  }

  /**
   * Gets a picture from local storage, if it is not expired.
   *
   * Otherwise, gets a picture new daily picture for the user, depending on their preferences.
   */
  getPicture(): Observable<any> {
    let picture = window.localStorage.picture;

    if (picture !== undefined) {
      picture = JSON.parse(window.localStorage.picture);

      if (picture.expiry > new Date().valueOf()) {
        if ((
            picture.url.includes('cataas') &&
            this.user.preferredAnimal === 'cat'
          ) || (
            picture.url.includes('random.dog') &&
            this.user.settings.preferredAnimal === 'dog'
        )) {
          return of(picture);
        }
      }
    }

    if (
      this.user.settings.preferredAnimal === 'dog' ||
      this.user.settings.preferredAnimal === 'dragon'
    ) {
      return this.getDogPicture();
    }

    const catUrl = 'https://cataas.com/cat?' + new Date().toISOString();

    window.localStorage.picture = JSON.stringify({
      url: catUrl,
      expiry: new Date().setHours(0, 0, 0, 0),
    });

    return of({
      url: catUrl,
    });
  }

  /**
   * Get a random dog picture and saves it to local storage.
   */
  private getDogPicture(): Observable<any> {
    const url = `https://random.dog/woof.json`;
    return this.http.get(url)
    .pipe(
      tap((res: any) => {
        window.localStorage.picture = JSON.stringify({
          url: res.url,
          expiry: new Date().setHours(23, 59, 59, 59),
        });
      }),
      catchError(handleError('getDogPicture', [])),
    );
  }
}
