import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { forkJoin, ReplaySubject, Subject } from 'rxjs';
import { catchError, delay, repeat, tap } from 'rxjs/operators';

import { handleError } from '../../common/functions/handleError';
import { DefaultResponse } from '../../common/interfaces/api-responses/default';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusService implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  private appStatus$: ReplaySubject<any[]> = new ReplaySubject();
  private dispatchStatus$: ReplaySubject<any[]> = new ReplaySubject();
  private radioStatus$: ReplaySubject<boolean> = new ReplaySubject();
  private imsStatus$: ReplaySubject<boolean> = new ReplaySubject();
  private bpStatus$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private http: HttpClient,
  ) {
    this.loadAppServerV2Status();
    this.loadDispatchServerStatus();
    this.loadRadioServerStatus();
    this.loadIMSServerStatus();
    this.loadBPServerStatus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAppServerV2Status(): ReplaySubject<any[]> {
    return this.appStatus$;
  }

  getDispatchServerStatus(): ReplaySubject<any[]> {
    return this.dispatchStatus$;
  }

  getRadioServerStatus(): ReplaySubject<boolean> {
    return this.radioStatus$;
  }

  getIMSServerStatus(): ReplaySubject<boolean> {
    return this.imsStatus$;
  }

  getBPServerStatus(): ReplaySubject<boolean> {
    return this.bpStatus$;
  }

  private loadAppServerV2Status(): void {
    forkJoin([
      this.http.get(
        'http://tor-app-04.whosresponding.com/api?module=whosresponding&method=$test&vhost=apppreview.wrserver.app',
      )
        .pipe(catchError(handleError('loadAppServerV2Status04', []))),
      this.http.get(
        'http://tor-app-05.whosresponding.com/api?module=whosresponding&method=$test&vhost=apppreview.wrserver.app',
        )
        .pipe(catchError(handleError('loadAppServerV2Status05', []))),
      this.http.get(
        'http://tor-app-06.whosresponding.com/api?module=whosresponding&method=$test&vhost=apppreview.wrserver.app',
        )
        .pipe(catchError(handleError('loadAppServerV2Status06', []))),
    ])
      .pipe(
        tap(res => this.appStatus$.next(res)),
        delay(60000),
        repeat(),
      )
      .subscribe();
  }

  private loadDispatchServerStatus(): void {
    forkJoin([
      this.http.get('http://tor-dispatch-04.whosresponding.com:12500/filters/dispatches')
      .pipe(catchError(handleError('getDispatchServerStatus04', []))),
      this.http.get('http://tor-dispatch-05.whosresponding.com:12500/filters/dispatches')
      .pipe(catchError(handleError('getDispatchServerStatus05', []))),
      this.http.get('http://tor-dispatch-06.whosresponding.com:12500/filters/dispatches')
        .pipe(catchError(handleError('getDispatchServerStatus06', []))),
    ])
      .pipe(
        tap(res => this.dispatchStatus$.next(res)),
        delay(60000),
        repeat(),
      )
      .subscribe();
  }

  private loadRadioServerStatus(): void {
    this.http.get('https://radio.fluentims.com/radio/stats')
      .pipe(
        catchError(handleError('loadRadioServerStatus', [])),
        tap((res: Array<{ streams: any[] }>) => this.radioStatus$.next(res[0]?.streams?.length > 0)),
        delay(60000),
        repeat(),
      )
      .subscribe();
  }

  private loadIMSServerStatus(): void {
    this.http.get('https://apppreview.ims-login.com/api?module=whosresponding&method=$test')
      .pipe(
        catchError(handleError('loadIMSServerStatus', [])),
        tap((res: DefaultResponse) => this.imsStatus$.next(res.errorCode === 0)),
        delay(60000),
        repeat(),
      )
      .subscribe();
  }

  private loadBPServerStatus(): void {
    this.http.get('https://ap.burnpermits.com/api.cfm?method=test')
      .pipe(
        catchError(handleError('loadBPServerStatus', [])),
        tap((res: DefaultResponse) => this.bpStatus$.next(res.errorCode === 0)),
        delay(60000),
        repeat(),
      )
      .subscribe();
  }

}
