import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import { handleError } from '../../common/functions/handleError';

import { API_URL } from '../../../environments/environment';
import { Region } from '../../common/interfaces/general/Region';
import { NamedObject } from '../../common/interfaces/general/NamedObject';
import { DefaultResponse } from '../../common/interfaces/api-responses/default';
import { SearchResult } from '../../common/interfaces/general/SearchResult';

const m2kmConversion: number = 3.6;

/**
 * Single point of access for methods that are the same across BP, WR, and other services.
 *
 * Also handles anything related to the currently logged in user.
 */
@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  private providers: any = null;
  private regions: Region[] = null;
  private countries: NamedObject[] = null;
  private languages: NamedObject[] = null;
  private timezones: any = null;

  constructor(
    private http: HttpClient,
  ) { }

  getSearchResults(term: string): Observable<( DefaultResponse & { results: SearchResult })> {
    const url = `${API_URL}?module=jackhammer.api&method=getSearchResults`;
    return this.http.post(url, {
      term,
    })
      .pipe(
        tap(_ => console.log('got search results')),
        catchError(handleError('getSearchResults', [])),
      );
  }

  /**
   * Fetches all available cell phone providers.
   *
   * Temporarily stores the list of providers to prevent duplicate requests.
   */
  getCellProviders(): Observable<any> {
    if (this.providers !== null) {
      return of(this.providers);
    }

    const url = `${API_URL}?module=jackhammer.api&method=getCellProviders`;
    return this.http.get(url)
    .pipe(
      tap(providers => this.providers = providers),
      catchError(handleError('getCellProviders', [])),
    );
  }

  /**
   * Fetches all states and provinces.
   *
   * Temporarily stores the list of states and provinces to prevent duplicate requests.
   */
  getRegions(): Observable<Region[]> {
    if (this.regions !== null) {
      return of(this.regions);
    }

    const url = `${API_URL}?module=jackhammer.api&method=getRegions`;
    return this.http.get(url)
    .pipe(
      tap((regions: Region[]) => this.regions = regions),
      catchError(handleError('getRegions', [])),
    );
  }

  /**
   * Fetches all available countries.
   *
   * Temporarily stores the list of countries to prevent duplicate requests.
   */
  getCountries(): Observable<NamedObject[]> {
    if (this.countries !== null) {
      return of(this.countries);
    }

    const url = `${API_URL}?module=jackhammer.api&method=getCountries`;
    return this.http.get(url)
    .pipe(
      tap((countries: NamedObject[]) => this.countries = countries),
      catchError(handleError('getCountries', [])),
    );
  }

  getLanguages(): Observable<NamedObject[]> {
    if (this.languages !== null) {
      return of(this.languages);
    }

    const url = `${API_URL}?module=jackhammer.api&method=getLanguages`;
    return this.http.get(url)
    .pipe(
      tap((languages: NamedObject[]) => this.languages = languages),
      catchError(handleError('getLanguages', [])),
    );
  }

  /**
   * Fetches all timezones.
   *
   * Temporarily stores the list of timezones to prevent duplicate requests.
   */
  getTimezones(): Observable<any> {
    if (this.timezones !== null) {
      return of(this.timezones);
    }

    const url = `${API_URL}?module=jackhammer.api&method=getTimezones`;
    return this.http.get(url)
    .pipe(
      tap(timezones => this.timezones = timezones),
      catchError(handleError('getTimezones', [])),
    );
  }

  /**
   * Gets the weather for Brockville using the OpenWeatherMap API.
   */
  getWeather(): Observable<any> {
    const lat = 44.624384;
    const lng = -75.699603;
    const key = 'ed514f4fe1f20ad341c82969ac170623';
    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lng}&appid=${key}&units=metric`;
    return this.http.get(url)
    .pipe(
      map((response: any) => {
        const weather: any = {};
        const icons: any = {
          '01d': 'ion-ios-sunny-outline',
          '02d': 'ion-ios-partlysunny-outline',
          '03d': 'ion-ios-cloudy-outline',
          '04d': 'ion-ios-cloudy-outline',
          '09d': 'ion-ios-rainy-outline',
          '10d': 'ion-ios-rainy-outline',
          '11d': 'ion-ios-thunderstorm-outline',
          '13d': 'ion-ios-snowy',
          '50d': 'ion-nuclear',
        };
        weather.temp = response.current.temp;
        weather.feelsLike = Math.round(response.current.feels_like);
        weather.windSpeed = (response.current.wind_speed * m2kmConversion).toFixed(1);
        weather.humidity = response.current.humidity;
        weather.max = Math.round(response.daily[0].temp.max);
        weather.min = Math.round(response.daily[0].temp.min);
        weather.daily = response.daily.slice(1, 5).map(day => {
          const dailyWeather: any = {};

          dailyWeather.temp = Math.round(day.temp.day);
          dailyWeather.icon = icons[day.weather[0].icon];
          dailyWeather.day = new Date(day.dt * 1000).toString().split(' ')[0];

          return dailyWeather;
        });

        return weather;
      }),
      catchError(handleError('getWeather', [])),
    );
  }

  getDownDevicesCount(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=getDownDevicesCount`;
    return this.http.get(url)
    .pipe(
      tap(),
      catchError(handleError('getDownDevicesCount', [])),
    );
  }

  getReport(range: {
    start: Date;
    end: Date;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=getReport`;
    return this.http.post(url, {
      range,
    })
    .pipe(
      tap(_ => console.log('got report')),
      catchError(handleError('getReport', [])),
    );
  }

  getInventory(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=getInventory`;
    return this.http.get(url)
    .pipe(
      tap(_ => console.log('fetched inventory')),
      catchError(handleError('getInventory', [])),
    );
  }

  getInventoryItems(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=getInventoryItems`;
    return this.http.get(url)
    .pipe(
      tap(_ => console.log('fetched inventory items')),
      catchError(handleError('getInventoryItems', [])),
    );
  }

  getInventoryLocations(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=getInventoryLocations`;
    return this.http.get(url)
    .pipe(
      tap(_ => console.log('fetched inventory locations')),
      catchError(handleError('getInventoryLocations', [])),
    );
  }

  addInventoryItem(item: {
    name: string;
    itemID: number;
    locationID: number;
    quantity: string;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=addInventoryItem`;
    return this.http.post(url, item)
    .pipe(
      tap(_ => console.log('added inventory item')),
      catchError(handleError('addInventoryItem', [])),
    );
  }

  addInventoryLocation(name: string): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=addInventoryLocation`;
    return this.http.post(url, {
      name,
    })
    .pipe(
      tap(_ => console.log('added inventory location')),
      catchError(handleError('addInventoryLocation', [])),
    );
  }

  updateInventory(data: {
    itemID: number;
    oldLocationID: number;
    locationID: number;
    quantity: number;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=updateInventory`;
    return this.http.post(url, data)
    .pipe(
      tap(_ => console.log('updated inventory')),
      catchError(handleError('updateInventory', [])),
    );
  }

  getTrackingOverview(range: {
    start: Date;
    end: Date;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingGetOverview`;
    return this.http.post(url, {
      range,
    })
    .pipe(
      tap(_ => console.log('got tracking overview')),
      catchError(handleError('getTrackingOverview', [])),
    );
  }

  getTrackingUsersOverview(range: {
    start: Date;
    end: Date;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingGetUsersOverview`;
    return this.http.post(url, {
      range,
    })
    .pipe(
      tap(_ => console.log('got tracking users overview')),
      catchError(handleError('getTrackingUsersOverview', [])),
    );
  }

  getTrackingUserProfile(userID: number, date: any): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingGetUserProfile`;
    return this.http.post(url, {
      userID,
      date,
    })
    .pipe(
      tap(_ => console.log('got tracking users overview')),
      catchError(handleError('getTrackingUsersOverview', [])),
    );
  }

  getTrackingActivities(): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingListActivities`;
    return this.http.get(url)
    .pipe(
      tap(_ => console.log('got tracking activities')),
      catchError(handleError('getTrackingActivities', [])),
    );
  }

  changeTrackingActivityStatus(activityID: number, currentStatus: number): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingChangeActivityStatus`;
    return this.http.post(url, {
      activityID,
      currentStatus,
    })
    .pipe(
      tap(_ => console.log('changed activity status')),
      catchError(handleError('changeTrackingActivityStatus', [])),
    );
  }

  getMergeableActivities(currentID: number): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingGetMergeableActivities`;
    return this.http.post(url, {
      currentID,
    })
    .pipe(
      tap(_ => console.log('got mergeable activities')),
      catchError(handleError('getMergeableActivities', [])),
    );
  }

  mergeActivities(data: {
    oldActivityID: number;
    newActivityID: number;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingMergeActivities`;
    return this.http.post(url, data)
    .pipe(
      tap(_ => console.log('merged activities')),
      catchError(handleError('mergeActivities', [])),
    );
  }

  updateActivity(data: {
    id: number;
    name: string;
    colour: string;
    atDesk: number;
  }): Observable<any> {
    const url = `${API_URL}?module=jackhammer.api&method=trackingUpdateActivities`;
    return this.http.post(url, data)
    .pipe(
      tap(_ => console.log('updated activity')),
      catchError(handleError('updateActivity', [])),
    );
  }
}
