import { NgModule } from '@angular/core';
import {
  NbCardModule,
  NbIconModule,
  NbButtonModule,
  NbInputModule,
  NbAlertModule,
  NbCheckboxModule,
} from '@nebular/theme';

import { ThemeModule } from '../../@theme/theme.module';
import { RegisterComponent } from './register.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    FormsModule,
    NbCardModule,
    NbIconModule,
    ThemeModule,
    NbButtonModule,
    NbInputModule,
    NbAlertModule,
    NbCheckboxModule,
    RouterModule,
  ],
  declarations: [
    RegisterComponent,
  ],
})
export class RegisterModule { }
