import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = Math.floor(value - (hours * 60));

    if (value === null) {
      return '0 mins';
    }

    let hourStr = '';
    let minStr = '';

    if (hours > 0) {
      hourStr = hours + ' hrs ';
    }

    if (minutes > 0) {
      minStr = minutes + ' mins';
    }

    return `${hourStr}${minStr}`;
  }

}
