import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NbAuthService, NB_AUTH_OPTIONS, getDeepFromObject, NbAuthResult } from '@nebular/auth';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {

  private redirectDelay: number = 0;
  private strategy: string = '';
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    protected authService: NbAuthService,
    protected router: Router,
    @Inject(NB_AUTH_OPTIONS) protected options: any = {},
  ) {
    this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
    this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit(): void {
    this.logout(this.strategy);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout(strategy: string): void {
    this.authService.logout(strategy)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: NbAuthResult) => {
      const redirect = result.getRedirect();

      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

}
