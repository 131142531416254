import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowRef } from '@nebular/theme';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GeneralService } from '../../../@core/services/general.service';
import { SearchResult } from '../../interfaces/general/SearchResult';

@Component({
  selector: 'ngx-search-results-window',
  templateUrl: './search-results-window.component.html',
  styleUrls: ['./search-results-window.component.scss'],
})
export class SearchResultsWindowComponent implements OnInit, OnDestroy {

  public searchResults: SearchResult;

  private unsubscribe$: Subject<void> = new Subject();
  private term: string;

  constructor(
    protected windowRef: NbWindowRef,
    private generalService: GeneralService,
    private router: Router,
  ) {
    this.term = (this.windowRef.config.context as { term: string }).term;
  }

  ngOnInit(): void {
    this.generalService.getSearchResults(this.term)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.searchResults = res.results;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  viewWRCompany(companyID: number): void {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/pages/whosresponding/companies', companyID]);
        this.windowRef.close();
      });
  }

  viewWRUser(userID: number): void {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/pages/whosresponding/users', userID]);
        this.windowRef.close();
      });
  }

  viewBPCompany(companyID: number): void {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/pages/permits/companies', companyID]);
        this.windowRef.close();
      });
  }

  viewBPUser(userID: number): void {
    this.router.navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/pages/permits/users', userID]);
        this.windowRef.close();
      });
  }

}
