import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSearchService,
  NbSidebarService,
  NbThemeService,
  NbWindowService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';

import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService } from '../../../@core/services/user.service';
import { GeneralService } from '../../../@core/services/general.service';
import { ServerStatusService } from '../../../@core/services/server-status.service';
import {
  ServerStatusWindowComponent,
} from '../../../common/dialogs/server-status-window/server-status-window.component';
import { DefaultResponse } from '../../../common/interfaces/api-responses/default';
import {
  SearchResultsWindowComponent,
} from '../../../common/dialogs/search-results-window/search-results-window.component';

import { DM_DASHBOARD_URL } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  public DM_DASHBOARD_URL: string = DM_DASHBOARD_URL;

  public userPictureOnly: boolean = false;
  public user: any;
  public devices: any;
  public validDate: boolean = false;

  public themes: Array<{
    value: string;
    name: string;
  }> = [
    {
      value: 'default',
      name: 'Light',
    }, {
      value: 'dark',
      name: 'Dark',
    }, {
      value: 'cosmic',
      name: 'Cosmic',
    }, {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  public currentTheme: string = 'default';

  public userMenu: Array<{
    title: string;
    link: string;
  }> = [
    {
      title: 'Profile',
      link: 'pages/profile',
    }, {
      title: 'Log out',
      link: 'auth/logout',
    },
  ];

  public tag: string = 'userMenu';

  public serverStatus: {
    app: number;
    dispatch: number;
    radio: boolean;
    ims: boolean;
    bp: boolean;
  } = {
    app: null,
    dispatch: null,
    radio: null,
    ims: null,
    bp: null,
  };

  public appServerTooltip: string;
  public dispatchServerTooltip: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private breakpointService: NbMediaBreakpointsService,
    private generalService: GeneralService,
    private layoutService: LayoutService,
    private menuService: NbMenuService,
    private searchService: NbSearchService,
    private serverStatusService: ServerStatusService,
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private userService: UserService,
    private windowService: NbWindowService,
  ) {
    let date: any = new Date();
    const month = date.getMonth();
    date = date.getDate();
    this.validDate = date > 23 && date < 30 && month === 11;
  }

  ngOnInit(): void {
    this.currentTheme = this.themeService.currentTheme;

    this.user = this.userService.getUser();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
    .pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      takeUntil(this.destroy$),
    )
    .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
    .pipe(
      map(({ name }) => name),
      takeUntil(this.destroy$),
    )
    .subscribe(themeName => this.currentTheme = themeName);

    this.getDownDevices();
    this.getServerStatuses();
    this.listenToSearchService();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string): void {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome(): boolean {
    this.menuService.navigateHome();

    return false;
  }

  viewServerStatus(): void {
    this.windowService.open(
      ServerStatusWindowComponent,
      {
        title: 'Server Statuses',
      },
    );
  }

  private getDownDevices(): void {
    this.generalService.getDownDevicesCount()
    .pipe(takeUntil(this.destroy$))
    .subscribe((devices: any) => this.devices = devices);
  }

  private getServerStatuses(): void {
    this.serverStatusService.getAppServerV2Status()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: DefaultResponse[]) => {
        let runningCount = 0;

        status.forEach(server => {
          if (server?.errorCode === 0) {
            runningCount++;
          }
        });

        this.serverStatus.app = runningCount / status.length;
        this.appServerTooltip = `${runningCount}/${status.length}`;
      });

    this.serverStatusService.getDispatchServerStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: any[]) => {
        let runningCount = 0;

        status.forEach(server => {
          if (server?.dispatches?.length > 0) {
            runningCount++;
          }
        });

        this.serverStatus.dispatch = runningCount / status.length;
        this.dispatchServerTooltip = `${runningCount}/${status.length}`;
      });

    this.serverStatusService.getRadioServerStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => this.serverStatus.radio = status);

    this.serverStatusService.getIMSServerStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => this.serverStatus.ims = status);

    this.serverStatusService.getBPServerStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => this.serverStatus.bp = status);
  }

  private listenToSearchService(): void {
    this.searchService.onSearchSubmit()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log(data);

        this.windowService.open(
          SearchResultsWindowComponent,
          {
            title: 'Search Results: ' + data.term,
            context: {
              term: data.term,
            },
          },
        );
      });
  }

}
